import { Divider, Flex, Grid, Heading } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import TeamBio from '../../components/meet-the-team/team-bio';
import SEO from '../../components/seo';

function MeetTheTeam({ data }) {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  const staff = data.teamMembers.nodes.filter((node) => node.type === 'staff');
  const consultants = data.teamMembers.nodes.filter(
    (node) => node.type === 'consultant'
  );

  staff.sort((a, b) => (a.order > b.order ? 1 : -1));
  consultants.sort((a, b) => (a.order > b.order ? 1 : -1));
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={6}
        mb={16}
      >
        <Heading as="h1" mt={{ base: 6, lg: 10 }}>
          Meet the team
        </Heading>
        <Heading
          as="h2"
          fontSize="1.6rem"
          fontWeight="semibold"
          mt={{ base: 6, lg: 12 }}
          color="gray.600"
        >
          Parentshop HQ, Byron Bay
        </Heading>
        <Divider mt={5} />
        <Grid
          templateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={{ base: 8, lg: 16 }}
          w="100%"
          mt={12}
        >
          {staff.map((staff) => {
            return (
              <TeamBio
                key={staff.name}
                name={staff.name}
                role={staff.role}
                bio={staff.bio.bio}
                imageSrc={getImage(staff.image.gatsbyImage)}
                alt={staff.image.title}
              />
            );
          })}
        </Grid>
        <Heading
          as="h2"
          fontSize="1.6rem"
          fontWeight="semibold"
          mt={16}
          color="gray.600"
        >
          Presenters & consultants
        </Heading>
        <Divider mt={5} />
        <Grid
          templateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={{ base: 8, lg: 16 }}
          w="100%"
          mt={12}
        >
          {consultants.map((consultant) => {
            return (
              <TeamBio
                key={consultant.name}
                name={consultant.name}
                role={consultant.role}
                bio={consultant.bio.bio}
                imageSrc={getImage(consultant.image.gatsbyImage)}
                alt={consultant.image.title}
              />
            );
          })}
        </Grid>
      </Flex>
    </>
  );
}

export default MeetTheTeam;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "meet-the-team" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    teamMembers: allContentfulTeamMember {
      nodes {
        image {
          title
          gatsbyImage(width: 1920)
        }
        name
        role
        type
        bio {
          bio
        }
        order
      }
    }
  }
`;
